/** @format */
'use client'
import { Box } from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { colors } from 'styles/foundations'

const ScrollProgressBar = () => {
  const [scrollPercentage, setScrollPercentage] = useState(0)

  useEffect(() => {
    const updateScrollPercentage = () => {
      const scrollHeight = document.documentElement.scrollHeight - window.innerHeight
      const scrolled = (window.scrollY / scrollHeight) * 100
      setScrollPercentage(scrolled)
    }

    window.addEventListener('scroll', updateScrollPercentage)

    return () => {
      window.removeEventListener('scroll', updateScrollPercentage)
    }
  }, [])

  return (
    <Box bg={'white'} w="full">
      <Box
        pos={'fixed'}
        top={0}
        left={0}
        h="2px"
        bg={colors.brand.primary}
        style={{
          width: `${scrollPercentage}%`,
          transition: 'width 0.3s ease'
        }}
      />
    </Box>
  )
}

export default ScrollProgressBar

/** @format */
'use client'
import { Button, Flex } from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { FaArrowUp } from 'react-icons/fa'

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    }

    window.addEventListener('scroll', toggleVisibility)

    return () => {
      window.removeEventListener('scroll', toggleVisibility)
    }
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  return (
    <>
      {isVisible && (
        <Button
          variant={'unstyled'}
          zIndex={'9999'}
          onClick={scrollToTop}
          pos="fixed"
          bottom={12}
          right={12}>
          <Flex
            bg="black"
            zIndex={'9999'}
            align={'center'}
            justify="center"
            w={10}
            h={10}
            borderRadius={5}>
            <FaArrowUp color="white" />
          </Flex>
        </Button>
      )}
    </>
  )
}

export default ScrollToTopButton

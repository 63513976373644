/** @format */

'use client'

import Image from 'next/image'
import Link from 'next/link'

type LogoProps = {
  className?: string
  width?: number | `${number}` | undefined
  height?: number | `${number}` | undefined
}

export const Logo = (props: LogoProps) => (
  <div
    className={`
      "flex items-center justify-between py-5 md:block"
    ${props.className}
    `}>
    <Link href={'/'}>
      <Image
        src="/images/Logo.png"
        width={props.width ?? 99}
        height={props.height ?? 36}
        alt="Lint Logo"
        loading="lazy"
        className={props.className}
      />
    </Link>
  </div>
)
